var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout_container"},[_c('header',[_c('div',{staticClass:"title"},[_c('div',{staticClass:"title_left",on:{"click":function($event){_vm.$router.push('/'), _vm.tab(0)}}},[_c('img',{attrs:{"src":require("@/assets/header/logo.png"),"alt":""}})]),_c('div',{staticClass:"title_right",on:{"click":function($event){return _vm.tab()}}},[(!_vm.tabShow)?_c('img',{attrs:{"src":require("@/assets/header/nav.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/header/close.png"),"alt":""}})])])]),_c('transition',{attrs:{"name":"fades"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabShow),expression:"tabShow"}],staticClass:"tab_none",attrs:{"translate":"fades"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.tab.apply(null, arguments)}}},[_c('ul',{class:_vm.tabShow ? 'animate__animated animate__fadeInDown' : ''},[_c('li',{staticClass:"li",on:{"click":function($event){$event.stopPropagation();_vm.$router.push('/'), _vm.tab()}}},[_vm._v("首页")]),_c('li',{staticClass:"li",on:{"click":function($event){$event.stopPropagation();return _vm.linkTo(2)}}},[_c('div',{staticClass:"link_title"},[_c('span',[_vm._v("关于我们")]),_c('svg',{staticClass:"icon",class:{
                arrowTransformReturn: _vm.showItem2,
                arrowTransform: !_vm.showItem2,
              },attrs:{"t":"1629426996097","viewBox":"0 0 1024 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","p-id":"2781"}},[_c('path',{attrs:{"d":"M938.15 286.84c-25.09-24-65.87-24-91.06 0L512 607.87l-335.11-321c-25.14-24-65.87-24-91 0s-25.14 63.06 0 87.14l379.3 363.3a70.28 70.28 0 0 0 93.69 0L938.15 374c25.13-24.11 25.13-63.13 0-87.16z","p-id":"2782","fill":"#ffffff"}})])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showItem2),expression:"showItem2"}],staticClass:"ul_items",class:_vm.showItem2 ? 'animate__animated animate__bounceIn' : ''},[_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.aboutUs(0)}}},[_vm._v("公司简介")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.aboutUs(1)}}},[_vm._v("发展历程")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.aboutUs(2)}}},[_vm._v("员工风采")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.aboutUs(3)}}},[_vm._v("合作伙伴")])])]),_c('li',{staticClass:"li",on:{"click":function($event){$event.stopPropagation();_vm.linkTo(3), _vm.tab()}}},[_c('div',{staticClass:"link_title"},[_c('span',[_vm._v("新闻中心")])])]),_c('li',{staticClass:"li",on:{"click":function($event){$event.stopPropagation();return _vm.linkTo(4)}}},[_c('div',{staticClass:"link_title"},[_c('span',[_vm._v("服务项目")]),_c('svg',{staticClass:"icon",class:{
                arrowTransformReturn: _vm.showItem4,
                arrowTransform: !_vm.showItem4,
              },attrs:{"t":"1629426996097","viewBox":"0 0 1024 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","p-id":"2781"}},[_c('path',{attrs:{"d":"M938.15 286.84c-25.09-24-65.87-24-91.06 0L512 607.87l-335.11-321c-25.14-24-65.87-24-91 0s-25.14 63.06 0 87.14l379.3 363.3a70.28 70.28 0 0 0 93.69 0L938.15 374c25.13-24.11 25.13-63.13 0-87.16z","p-id":"2782","fill":"#ffffff"}})])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showItem4),expression:"showItem4"}],staticClass:"ul_items",class:_vm.showItem4 ? 'animate__animated animate__bounceIn' : ''},[_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.serveProject(0)}}},[_vm._v("居家照顾")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.serveProject(1)}}},[_vm._v("长护险服务")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.serveProject(2)}}},[_vm._v("医院陪护")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.serveProject(3)}}},[_vm._v("老年餐定制")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.serveProject(4)}}},[_vm._v("适老化改造")])])]),_c('li',{staticClass:"li",on:{"click":function($event){$event.stopPropagation();_vm.linkTo(5), _vm.tab()}}},[_vm._v("服务站点")]),_c('li',{staticClass:"li",on:{"click":function($event){$event.stopPropagation();return _vm.linkTo(6)}}},[_c('div',{staticClass:"link_title"},[_c('span',[_vm._v("加入我们")]),_c('svg',{staticClass:"icon",class:{
                arrowTransformReturn: _vm.showItem6,
                arrowTransform: !_vm.showItem6,
              },attrs:{"t":"1629426996097","viewBox":"0 0 1024 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","p-id":"2781"}},[_c('path',{attrs:{"d":"M938.15 286.84c-25.09-24-65.87-24-91.06 0L512 607.87l-335.11-321c-25.14-24-65.87-24-91 0s-25.14 63.06 0 87.14l379.3 363.3a70.28 70.28 0 0 0 93.69 0L938.15 374c25.13-24.11 25.13-63.13 0-87.16z","p-id":"2782","fill":"#ffffff"}})])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showItem6),expression:"showItem6"}],staticClass:"ul_items",class:_vm.showItem6 ? 'animate__animated animate__bounceIn' : ''},[_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.partner(0)}}},[_vm._v("招贤纳士")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.partner(1)}}},[_vm._v("异业合作")]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.partner(2)}}},[_vm._v("志愿者招募")])])])])])]),_c('div',{ref:"fu",staticClass:"right_nav",on:{"click":_vm.onClick,"mousedown":_vm.down,"touchstart":_vm.down,"mousemove":_vm.move,"touchmove":_vm.move,"mouseup":_vm.end,"touchend":_vm.end}},[_c('right-window',{attrs:{"tabShow":_vm.tabShow}})],1),_c('router-view'),_c('footer',{ref:"footer"},[_vm._m(0),_vm._m(1),_vm._m(2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer_logo"},[_c('img',{attrs:{"src":require("@/assets/home/logo.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer_address"},[_c('p',[_vm._v("咨询热线： 400-180-1219")]),_c('p',[_vm._v("总部地址：上海浦东新区崮山路968号")]),_c('p',[_vm._v("德心居护理站：上海浦东新区东三桥路237-2号")]),_c('p',[_vm._v("艾高护护理站：上海浦东新区和龙路447-3号")]),_c('p',[_vm._v("爱介护沪东为老服务中心：上海浦东新区五莲路430号")]),_c('p',[_vm._v("爱介护北蔡为老服务中心：上海浦东新区锦尊路229号")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer_icon"},[_c('div',{staticClass:"weixin"},[_c('img',{attrs:{"src":require("@/assets/home/weixin.png"),"alt":""}}),_c('p',[_vm._v("微信服务号")])]),_c('div',{staticClass:"weibo"},[_c('img',{attrs:{"src":require("@/assets/home/weibo.png"),"alt":""}}),_c('p',[_vm._v("微博")])])])}]

export { render, staticRenderFns }