<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-18 13:58:22
 * @LastEditors: ------
 * @LastEditTime: 2021-11-01 17:12:33
-->
<template>
  <div class="layout_container">
    <!-- 全局头部 -->
    <header>
      <div class="title">
        <div class="title_left" @click="$router.push('/'), tab(0)">
          <img src="@/assets/header/logo.png" alt="" />
        </div>
        <div class="title_right" @click="tab()">
          <img v-if="!tabShow" src="@/assets/header/nav.png" alt="" />
          <img v-else src="@/assets/header/close.png" alt="" />
        </div>
      </div>
    </header>
    <!-- 显示隐藏子选项导航栏 -->
    <transition name="fades">
      <div
        class="tab_none"
        v-show="tabShow"
        translate="fades"
        @click.self="tab"
      >
        <ul :class="tabShow ? 'animate__animated animate__fadeInDown' : ''">
          <li class="li" @click.stop="$router.push('/'), tab()">首页</li>
          <li class="li" @click.stop="linkTo(2)">
            <div class="link_title">
              <span>关于我们</span>
              <svg
                t="1629426996097"
                class="icon"
                :class="{
                  arrowTransformReturn: showItem2,
                  arrowTransform: !showItem2,
                }"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2781"
              >
                <path
                  d="M938.15 286.84c-25.09-24-65.87-24-91.06 0L512 607.87l-335.11-321c-25.14-24-65.87-24-91 0s-25.14 63.06 0 87.14l379.3 363.3a70.28 70.28 0 0 0 93.69 0L938.15 374c25.13-24.11 25.13-63.13 0-87.16z"
                  p-id="2782"
                  fill="#ffffff"
                ></path>
              </svg>
            </div>
            <ul
              class="ul_items"
              v-show="showItem2"
              :class="showItem2 ? 'animate__animated animate__bounceIn' : ''"
            >
              <li @click.stop="aboutUs(0)">公司简介</li>
              <li @click.stop="aboutUs(1)">发展历程</li>
              <li @click.stop="aboutUs(2)">员工风采</li>
              <li @click.stop="aboutUs(3)">合作伙伴</li>
            </ul>
          </li>
          <li class="li" @click.stop="linkTo(3), tab()">
            <div class="link_title">
              <span>新闻中心</span>
            </div>
          </li>
          <li class="li" @click.stop="linkTo(4)">
            <div class="link_title">
              <span>服务项目</span>
              <svg
                t="1629426996097"
                class="icon"
                :class="{
                  arrowTransformReturn: showItem4,
                  arrowTransform: !showItem4,
                }"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2781"
              >
                <path
                  d="M938.15 286.84c-25.09-24-65.87-24-91.06 0L512 607.87l-335.11-321c-25.14-24-65.87-24-91 0s-25.14 63.06 0 87.14l379.3 363.3a70.28 70.28 0 0 0 93.69 0L938.15 374c25.13-24.11 25.13-63.13 0-87.16z"
                  p-id="2782"
                  fill="#ffffff"
                ></path>
              </svg>
            </div>
            <ul
              class="ul_items"
              v-show="showItem4"
              :class="showItem4 ? 'animate__animated animate__bounceIn' : ''"
            >
              <li @click.stop="serveProject(0)">居家照顾</li>
              <li @click.stop="serveProject(1)">长护险服务</li>
              <li @click.stop="serveProject(2)">医院陪护</li>
              <li @click.stop="serveProject(3)">老年餐定制</li>
              <li @click.stop="serveProject(4)">适老化改造</li>
            </ul>
          </li>
          <li class="li" @click.stop="linkTo(5), tab()">服务站点</li>
          <li class="li" @click.stop="linkTo(6)">
            <div class="link_title">
              <span>加入我们</span>
              <svg
                t="1629426996097"
                class="icon"
                :class="{
                  arrowTransformReturn: showItem6,
                  arrowTransform: !showItem6,
                }"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2781"
              >
                <path
                  d="M938.15 286.84c-25.09-24-65.87-24-91.06 0L512 607.87l-335.11-321c-25.14-24-65.87-24-91 0s-25.14 63.06 0 87.14l379.3 363.3a70.28 70.28 0 0 0 93.69 0L938.15 374c25.13-24.11 25.13-63.13 0-87.16z"
                  p-id="2782"
                  fill="#ffffff"
                ></path>
              </svg>
            </div>
            <ul
              class="ul_items"
              v-show="showItem6"
              :class="showItem6 ? 'animate__animated animate__bounceIn' : ''"
            >
              <li @click.stop="partner(0)">招贤纳士</li>
              <li @click.stop="partner(1)">异业合作</li>
              <li @click.stop="partner(2)">志愿者招募</li>
            </ul>
          </li>
        </ul>
      </div>
    </transition>
    <!-- 右方悬浮球导航 -->
    <div
      class="right_nav"
      @click="onClick"
      @mousedown="down"
      @touchstart="down"
      @mousemove="move"
      @touchmove="move"
      @mouseup="end"
      @touchend="end"
      ref="fu"
    >
      <right-window :tabShow="tabShow"></right-window>
    </div>
    <!-- 其他页面路由出口 -->
    <router-view />
    <footer ref="footer">
      <div class="footer_logo">
        <img src="@/assets/home/logo.png" alt="" />
      </div>
      <div class="footer_address">
        <p>咨询热线： 400-180-1219</p>
        <p>总部地址：上海浦东新区崮山路968号</p>
        <p>德心居护理站：上海浦东新区东三桥路237-2号</p>
        <p>艾高护护理站：上海浦东新区和龙路447-3号</p>
        <p>爱介护沪东为老服务中心：上海浦东新区五莲路430号</p>
        <p>爱介护北蔡为老服务中心：上海浦东新区锦尊路229号</p>
      </div>
      <div class="footer_icon">
        <div class="weixin">
          <img src="@/assets/home/weixin.png" alt="" />
          <p>微信服务号</p>
        </div>
        <div class="weibo">
          <img src="@/assets/home/weibo.png" alt="" />
          <p>微博</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import { MaoDian } from "../../utils/maoDiao";
import rightWindow from "./components/window.vue";

export default {
  name: "Layout",
  components: { rightWindow },
  data() {
    return {
      tabShow: false, //点击
      value: 1,
      showItem2: false,
      showItem3: false,
      showItem4: false,
      showItem5: false,
      showItem6: false,
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
    };
  },
  methods: {
    tab(val) {
      // this.tabShow = !this.tabShow;
      if (val == 0) {
        this.tabShow = false;
        document.documentElement.style.overflow = "auto";
      } else {
        this.tabShow = !this.tabShow;
      }
      if (this.tabShow) {
        //如果遮罩层显示就禁止下方内容可以滚动
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "auto";
      }
    },

    aboutUs(val) {
      this.$router.push({
        path: "/aboutUs",
      });
      this.$store.commit("setjobNum", val);
      this.tab();
      // this.tabShow = false;
    },
    serveProject(val) {
      this.$router.push({
        path: "/serveproject",
      });
      this.$store.commit("setserveProject", val);
      this.tab();
    },
    partner(val) {
      this.$router.push({
        path: "/partner",
      });
      this.$store.commit("setPartner", val);
      this.tab();
    },
    linkTo(value) {
      this.value = value;
      if (value == 1) {
        this.$router.push("/");
      } else if (value == 2) {
        this.showItem2 = !this.showItem2;
      } else if (value == 3) {
        this.$router.push("/newscenter");
      } else if (value == 4) {
        this.showItem4 = !this.showItem4;
      } else if (value == 5) {
        this.$router.push("/site");
      } else if (value == 6) {
        this.showItem6 = !this.showItem6;
      }
    },
    down() {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.fu.offsetLeft;
      this.dy = this.$refs.fu.offsetTop;
    },
    move() {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
        let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
        this.xPum < 0 && (this.xPum = 0);
        this.yPum < 0 && (this.yPum = 0);
        this.xPum > width && (this.xPum = width);
        this.yPum > height && (this.yPum = height);
        // if (this.xPum >= 0 && this.yPum >= 0 && this.xPum<= width &&this.yPum<= height) {
        this.$refs.fu.style.left = this.xPum + "px";
        this.$refs.fu.style.top = this.yPum + "px";
        // }
        //阻止页面的滑动默认事件
        document.addEventListener(
          "touchmove",
          function() {
            event.preventDefault();
          },
          false
        );
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
    onClick() {
      //在这里我是作为子组件来使用的
      this.$emit("click");
    },
  },
  watch: {
    $route(to) {
      if (to.path == "/newsdetail") {
        this.$refs.footer.style.display = "none";
      } else {
        this.$refs.footer.style.display = "block";
      }
    },
  },
};
</script>

<style scoped lang="less">
svg {
  width: 16px;
  height: 16px;
}
header {
  height: 56px;
}
.fades-enter-active,
.fades-leave-active {
  transition: opacity 0.3s ease-out;
}
.fades-enter,
.fades-leave-active {
  opacity: 0;
}
.title {
  font-size: 18px;
  height: 56px;
  width: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7faff;

  .title_right,
  .title_left {
    padding: 0 15px;
  }
  .title_left {
    img {
      width: 168px;
      height: 38px;
    }
  }
  .title_right {
    img {
      width: 20px;
      height: 18px;
    }
  }
}
.tab_none {
  position: fixed;
  z-index: 999999999999;
  right: 0;
  left: 0;
  bottom: 0;
  top: 56px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  color: var(--white);
  overflow-y: scroll;
  // pointer-events: none;  // 禁用鼠标的操作
  ul {
    background: var(--themeColor);
    .li {
      padding: 16px 10px;
      padding-right: 20px;
      border-top: 1px solid rgb(219, 219, 219);
      .link_title {
        display: flex;
        justify-content: space-between;
        .arrowTransform {
          transition: 0.2s;
          transform-origin: center;
          transform: rotateZ(180deg);
        }
        .arrowTransformReturn {
          transition: 0.2s;
          transform-origin: center;
          transform: rotateZ(0deg);
        }
      }
      .ul_items {
        margin-top: 8px;
        font-size: 15px;
        li {
          padding: 10px 16px;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
// 悬浮球样式--------------------
.right_nav {
  position: fixed; //定位
  right: 20px; //初始x轴位置
  top: 70%; //初始Y轴位置
  touch-action: none; //这个是重点如果不加新版谷歌会忽略掉touch方法
  text-align: center;
  width: 48px;
  height: 48px;
  line-height: 48px;
  z-index: 999;
}
footer {
  background: #f2f2f2;
  text-align: center;
  font-size: 16px;
  color: var(--color666);
  padding: 40px 0;
  .footer_logo {
    img {
      width: 90px;
      height: 38px;
    }
  }
  .footer_address {
    text-align: left;
    padding: 16px;
    line-height: 30px;
  }
  .footer_icon {
    justify-content: center;
    display: flex;
    .weixin,
    .weibo {
      margin: 10px 18px;
      img {
        width: 110px;
        height: 110px;
      }
    }
  }
}
</style>
