<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-23 09:06:24
 * @LastEditors: ------
 * @LastEditTime: 2021-09-16 16:46:32
-->
<template>
  <div class="">
    <div is-link @click="showPopup" class="popu">
      +
    </div>
    <van-popup v-model="props_tabShow" class="popu_container">
      <div>
        <img
          src="@/assets/popu/X.png"
          alt=""
          class="closeImg"
          @click="props_tabShow = false"
        />
        <div class="popu_item">
          <div :class="tip_show ? 'popu_item_tip' : ''" class="first_img">
            <img
              src="@/assets/popu/phone.png"
              alt=""
              @touchstart="tipShow(1)"
            />
          </div>
          <div :class="tip_show2 ? 'popu_item_tip2 ' : ''">
            <img src="@/assets/popu/eml.png" @touchstart="tipShow(2)" alt="" />
          </div>
          <div>
            <img
              src="@/assets/popu/address.png"
              alt=""
              @click="$router.push('/site'), (props_tabShow = false)"
            />
          </div>
          <div>
            <img
              src="@/assets/popu/fanKui.png"
              alt=""
              @click="$router.push('/partner'), (props_tabShow = false)"
            />
          </div>
          <div :class="tip_show5 ? 'popu_item_tip5 ' : ''">
            <img
              src="@/assets/popu/account.png"
              @touchstart="tipShow(5)"
              alt=""
            />
          </div>
          <div :class="tip_show6 ? 'popu_item_tip6 ' : ''">
            <img
              src="@/assets/popu/weiBo.png"
              @touchstart="tipShow(6)"
              alt=""
            />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "floatWin",
  // props: ["value"],
  props: {
    tabShow: Boolean,
  },

  data() {
    return {
      tip_show: false,
      tip_show2: false,
      tip_show5: false,
      tip_show6: false,
      props_tabShow: false,
    };
  },
  // 监听属性 类似于data概念
  computed: {
    tabShows() {
      return this.tabShow;
    },
  },
  // 监控data中的数据变化
  watch: {
    tabShows(newVal) {
      // 监听vuex 数据变化 ， 如果改变就重新赋值
      if (!newVal) {
        this.props_tabShow = newVal;
      }
    },
  },
  // 方法集合
  methods: {
    showPopup() {
      this.props_tabShow = true;
    },
    tipShow(num) {
      if (num == 1) {
        this.tip_show2 = false;
        this.tip_show5 = false;
        this.tip_show6 = false;
        this.tip_show = true;
      } else if (num == 2) {
        this.tip_show2 = true;
        this.tip_show5 = false;
        this.tip_show6 = false;
        this.tip_show = false;
      } else if (num == 5) {
        this.tip_show2 = false;
        this.tip_show5 = true;
        this.tip_show6 = false;
        this.tip_show = false;
      } else if (num == 6) {
        this.tip_show2 = false;
        this.tip_show5 = false;
        this.tip_show6 = true;
        this.tip_show = false;
      }
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 这里存放数据
};
</script>
<style lang="less" scoped>
.popu {
  background: #03d171;
  height: 48px;
  width: 48px;
  line-height: 48px;
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 52px;
  box-shadow: 0px 3px 10px rgba(3, 209, 113, 0.3);
  border-radius: 50%;
}
.popu_container {
  background-color: transparent;
  text-align: right;
  div {
    width: 100vw;
    .closeImg {
      position: absolute;
      right: 0px;
      padding: 10px 20px 10px 46px;
      background-color: var(--white);
      border-top-left-radius: 10px;
      width: 18px;
      height: 18px;
    }
    .popu_item {
      padding: 38px 0 24px 0;
      div {
        position: relative;
        height: 74px;
        img {
          background-color: var(--white);
          width: 84px;
          height: 84px;
          // user-select: none;
          // -webkit-user-select: none;
          // -ms-user-select: none;
          // -moz-user-select: none;
          // touch-callout: none;
          // -webkit-touch-callout: none;
          // -ms-touch-callout: none;
          // -moz-touch-callout: none;
        }
      }
      .popu_item_tip {
        &::after {
          content: "400-180-1219";
          position: absolute;
          right: 100px;
          color: var(--white);
          font-size: 26px;
          font-weight: bold;
          width: 230px;
          bottom: 0px;
          background-color: #8e8e93;
          text-align: center;
          border-radius: 6px;
          padding: 12px 0;
        }
        &::before {
          content: "";
          display: inline-block;
          position: relative;
          top: -40px;
          left: -1px;
          width: 0;
          height: 0;
          border: 8px solid;
          border-color: transparent transparent #8e8e93;
          transform: rotate(90deg);
        }
      }
      .popu_item_tip2 {
        &::after {
          content: "business@aijiehu.cn";
          position: absolute;
          right: 100px;
          color: var(--white);
          font-size: 22px;
          font-weight: bold;
          width: 230px;
          bottom: 0px;
          background-color: #8e8e93;
          text-align: center;
          border-radius: 6px;
          padding: 12px 0;
        }
        &::before {
          content: "";
          display: inline-block;
          position: relative;
          top: -40px;
          left: -1px;
          width: 0;
          height: 0;
          border: 8px solid;
          border-color: transparent transparent #8e8e93;
          transform: rotate(90deg);
        }
      }
      .popu_item_tip5 {
        &::after {
          content: "";
          position: absolute;
          right: 100px;
          color: var(--white);
          background: url("../../../assets/home/weixin.png") no-repeat;
          background-size: 148px 148px;
          background-position: 39px 27px;
          height: 180px;
          width: 230px;
          bottom: 0px;
          background-color: #8e8e93;
          border-radius: 6px;
          padding: 12px 0;
        }
        &::before {
          content: "";
          display: inline-block;
          position: relative;
          top: -40px;
          left: -1px;
          width: 0;
          height: 0;
          border: 8px solid;
          border-color: transparent transparent #8e8e93;
          transform: rotate(90deg);
        }
      }
      .popu_item_tip6 {
        &::after {
          content: "";
          position: absolute;
          right: 100px;
          color: var(--white);
          background: url("../../../assets/home/weibo.png") no-repeat;
          background-size: 148px 148px;
          background-position: 39px 27px;
          height: 180px;
          width: 230px;
          bottom: 0px;
          background-color: #8e8e93;
          border-radius: 6px;
          padding: 12px 0;
        }
        &::before {
          content: "";
          display: inline-block;
          position: relative;
          top: -40px;
          left: -1px;
          width: 0;
          height: 0;
          border: 8px solid;
          border-color: transparent transparent #8e8e93;
          transform: rotate(90deg);
        }
      }
    }
  }
}
.van-popup--center {
  left: 100%;
  -webkit-transform: translate3d(-100%, -30%, 0);
  transform: translate3d(-100%, -30%, 0);
}
</style>
